import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BreadcrumbModule, MenuItem } from 'primeng/primeng';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { AppMaterialModule } from './app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NotFoundComponent } from './not-found/not-found.component';
import { PrivacyNoticeComponent} from './common/privacy-notice/privacy-notice.component';
import { CeoComponent } from './team/ceo/ceo.component';
import { SpecialitiesComponent } from './specialities/specialities.component';
import { ProductsComponent } from './products/products.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ToolsComponent } from './tools/tools.component';
import { TermsOfUseComponent } from './common/terms-of-use/terms-of-use.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    PrivacyNoticeComponent,
    TermsOfUseComponent,
    CeoComponent,
    SpecialitiesComponent,
    ProductsComponent,
    SolutionsComponent,
    ToolsComponent
  ],
  imports: [
BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    AngularFontAwesomeModule,
    BreadcrumbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
