import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { environment as env } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public env = env;
  
  moreIcons: boolean = false;
  ngOnInit() {}
  toggleViewIcons($event, _type){
    if(_type=='more'){
      this.moreIcons =true;
    }else{
      this.moreIcons =false;
    }
  }
}
