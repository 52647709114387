import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ceo',
  templateUrl: './ceo.component.html',
  styleUrls: ['./ceo.component.css']
})
export class CeoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
