import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { PrivacyNoticeComponent} from "./common/privacy-notice/privacy-notice.component";
import { TermsOfUseComponent} from "./common/terms-of-use/terms-of-use.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import {CeoComponent} from "./team/ceo/ceo.component";
import { SpecialitiesComponent } from "./specialities/specialities.component";
import { ProductsComponent } from "./products/products.component";
import { SolutionsComponent } from "./solutions/solutions.component";
import { ToolsComponent } from "./tools/tools.component";

const routes: Routes = [
  { path: "specialities", component: SpecialitiesComponent },
  { path: "products", component: ProductsComponent },
  { path: "solutions", component: SolutionsComponent },
  { path: "tools", component: ToolsComponent },
  { path: "home", component: HomeComponent },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path:"brahma", component:CeoComponent},
  { path:"privacy-notice", component:PrivacyNoticeComponent},
  { path:"terms-of-use", component:TermsOfUseComponent},
  { path: "home", component: HomeComponent },
  { path: "not-found", component: NotFoundComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
