import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute,
    private location: Location) { }
   turn1=false;
   turn2=false;
   turn3=false;
   turn4=false;
   turn5=false;
   refresh(){
     location.reload();
   }

  ngOnInit() {

  }


}
